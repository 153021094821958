@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
    margin: 0;
    font-family: 'Manrope', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #252737;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #0000 inset !important;
    -webkit-text-fill-color: white !important;
    transition: background-color 5000s ease-in-out 500000s;
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}